module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"8163814","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ursanex","short_name":"Ursanex","start_url":"/","background_color":"#ffffff","theme_color":"#009f95","display":"standalone","icon":"src/images/icon-padded.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a3a4d470c056e28e19db9981b62f199"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
