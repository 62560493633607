import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    callToAction: '#00FFF0',
  },
  fonts: {
    body: 'poppins, sans-serif',
    heading: 'poppins, sans-serif',
  },
})

export default theme
